import Image from "next/image";
import React, { useEffect, useState } from "react";

import logoDark from "@/assets/images/logo/seed-logo-light-m.svg";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import JoinNewslatter from "../common/joinNewslatter";

import GitHub from "@/assets/images/icons/Social-media/GitHub.svg";
import Instagram from "@/assets/images/icons/Social-media/Instagram.svg";
import LinkedIn from "@/assets/images/icons/Social-media/LinkedIn.svg";
import Medium from "@/assets/images/icons/Social-media/Medium.svg";
import Reddit from "@/assets/images/icons/Social-media/Reddit.svg";
import Telegram from "@/assets/images/icons/Social-media/Telegram.svg";
import Tiktok from "@/assets/images/icons/Social-media/Tiktok.svg";
import youtube from "@/assets/images/icons/Social-media/YouTube.svg";
import Twitter from "@/assets/images/icons/Social-media/twitter-inner.svg";
import { getCookie } from "@/config/helpers/api_helper";
import { createSelectorMyProfileData } from "@/config/helpers/createReduxSelectors";
import { getMyProfile, resetMyProfile } from "@/store/actions";
import { checkUserHasArtistRole } from "../common/utiles";

// const UseMetaMask = dynamic(() => import("@/config/helpers/web3Logic"), {
//   ssr: false,
// });

// const selectExclusiveCollectionsState = (state) => state.ExclusiveCollections;

function Footer() {
  const [isMarketplaceOpen, setIsMarketplaceOpen] = useState(false);
  const [myUser, setMyUser] = useState(null);

  const dispatch = useDispatch();
  // const [nftData, setNftData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [storageItem, setStorageItem] = useState(null);
  const [isAuthUser, setIsAuthUser] = useState(false);

  const { error, success, MyProfileUser, message, loading } = useSelector(
    createSelectorMyProfileData
  );

  // const { loadingExclusiveNfts, nfts } = useSelector(selectExclusiveNftsData);

  // useEffect(() => {
  //   if (isEmpty(nfts) && nfts === null) {
  //     setTimeout(() => {
  //       dispatch(ExclusiveData());
  //     }, 1200);
  //   }
  // }, [dispatch, nfts]);

  // useEffect(() => {
  //   if (!isEmpty(nfts)) {
  //     setNftData(nfts);
  //   }
  // }, [nfts, nftData]);

  useEffect(() => {
    // Check if running on client side
    if (typeof window !== "undefined" && getCookie("authentication") !== "") {
      const item = JSON.parse(getCookie("authentication"));
      setStorageItem(item);
      setIsAuthUser(!!getCookie("authentication"));
    }
  }, [isAuthUser]);

  useEffect(() => {
    if (storageItem && isEmpty(MyProfileUser)) {
      setTimeout(() => {
        dispatch(getMyProfile(storageItem.UserName));
      }, 300);
    }
  }, [storageItem, MyProfileUser, userData, dispatch]);

  useEffect(() => {
    setUserData(MyProfileUser?.User);
  }, [MyProfileUser, userData]);

  //

  useEffect(() => {
    if (!isEmpty(MyProfileUser)) {
      setMyUser(MyProfileUser.User);
    }
  }, [MyProfileUser, myUser]);

  const handleClickMarketplace = () => {
    setIsMarketplaceOpen(!isMarketplaceOpen);
  };

  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const handleClickCompany = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  const [isContactOpen, setIsContactOpen] = useState(false);

  const handleClickContact = () => {
    setIsContactOpen(!isContactOpen);
  };

  const MinimalConnectWallet = dynamic(
    () => import("@/config/helpers/minimalConnectWallet"),
    {
      ssr: false,
    }
  );

  return (
    <>
      <div id="All-popups"></div>
      <footer className="footernew">
        <div className="footernew__center center">
          <div className="footernew__row">
            <div className="footernew__col">
              <Link className="footernew__logo" href="/">
                <Image
                  width={128}
                  height={37}
                  loading="lazy"
                  src={logoDark}
                  alt="Crypter NFT"
                />
              </Link>
              <div className="footernew__info">
                Seed Your Artwork In Our Fields
              </div>
            </div>
            <div className="footernew__col">
              <div className="footernew__group">
                <div
                  className={`footernew__head  ${
                    isMarketplaceOpen ? "" : "active"
                  }`}
                  onClick={handleClickMarketplace}
                >
                  Marketplace
                  <svg className="iconnew icon-arrow-bottom">
                    <use xlinkHref="#icon-arrow-bottom"></use>
                  </svg>
                </div>
                <div
                  className="footernew__body"
                  style={
                    isMarketplaceOpen === true
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="footernew__menu">
                    {typeof window !== "undefined" &&
                    getCookie("authentication") !== "" &&
                    !!getCookie("authentication") &&
                    userData &&
                    !isEmpty(MyProfileUser) ? (
                      checkUserHasArtistRole(MyProfileUser?.User) ? (
                        <Link
                          className="footernew__link"
                          href="/nfts/upload"
                          target="_blank"
                        >
                          Create item
                        </Link>
                      ) : (
                        <>
                          <Link
                            className="footernew__link"
                            href="/account/verification-form"
                            onClick={() => {
                              dispatch(resetMyProfile());
                            }}
                          >
                            Join Now
                          </Link>
                        </>
                      )
                    ) : (
                      <MinimalConnectWallet
                        userData={userData}
                        isHeader={true}
                        textButton={"Connect Wallet"}
                        customeClass="footernew__link"
                      />
                    )}

                    <Link
                      className="footernew__link"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>

                    <Link
                      className="footernew__link"
                      href="/community-guidelines"
                      target="_blank"
                    >
                      Community Guidelines
                    </Link>
                  </div>
                </div>
              </div>
              <div className="footernew__group">
                <div
                  className={`footernew__head  ${
                    isCompanyOpen ? "" : "active"
                  }`}
                  onClick={handleClickCompany}
                >
                  Company
                  <svg className="iconnew icon-arrow-bottom">
                    <use xlinkHref="#icon-arrow-bottom"></use>
                  </svg>
                </div>
                <div
                  className="footernew__body"
                  style={
                    isCompanyOpen === true
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="footernew__menu">
                    <Link
                      className="footernew__link"
                      href="/how-it-works/#faq"
                      target="_blank"
                    >
                      FAQs
                    </Link>
                    <Link
                      className="footernew__link"
                      href="/how-it-works"
                      target="_blank"
                    >
                      Support
                    </Link>
                    <Link
                      className="footernew__link"
                      href="/terms"
                      target="_blank"
                    >
                      T&Cs
                    </Link>
                  </div>
                </div>
              </div>
              <div className="footernew__group">
                <div
                  className={`footernew__head  ${
                    isContactOpen ? "" : "active"
                  }`}
                  onClick={handleClickContact}
                >
                  Contact
                  <svg className="iconnew icon-arrow-bottom">
                    <use xlinkHref="#icon-arrow-bottom"></use>
                  </svg>
                </div>
                <div
                  className="footernew__body"
                  style={
                    isContactOpen === true
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="footernew__menu">
                    <Link
                      className="footernew__link"
                      href="mailto:support@seed.photo"
                      target="_blank"
                    >
                      support@seed.photo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <JoinNewslatter />
          </div>
          <div className="footernew__foot">
            <div className="footernew__copyright">
              Copyright © {new Date().getFullYear()}{" "}
              <Link href="/" style={{ color: "#8e793e", fontWeight: "700" }}>
                Seed.Photo
              </Link>{" "}
              All rights reserved
            </div>
            <div id="acceptcookie" className="footernew__note">
              <Link
                href="https://www.youtube.com/@seedphoto"
                target="_blank"
                aria-label="youtube"
              >
                <Image
                  width={20}
                  height={20}
                  loading="lazy"
                  src={youtube}
                  alt="youtube-logo"
                />
              </Link>
              <Link href="https://github.com/seedphoto/" target="_blank">
                <Image
                  src={GitHub}
                  alt="GitHub-logo"
                  aria-label="GitHub"
                  className="footernew__twitterIcon"
                  width={20}
                  height={21}
                />
              </Link>
              <Link
                href="https://medium.com/@Seed.Photo"
                target="_blank"
                aria-label="medium"
              >
                <Image
                  width={26}
                  height={15}
                  loading="lazy"
                  src={Medium}
                  alt="Medium-logo"
                />
              </Link>
              <Link
                href="https://www.reddit.com/u/SeedNFTMarketplace/s/S784T3ZhN8"
                target="_blank"
                aria-label="Reddit"
              >
                <Image
                  width={24}
                  height={24}
                  loading="lazy"
                  src={Reddit}
                  alt="instagram-logo"
                />
              </Link>
              <Link href="https://t.me/SeedPhoto" target="_blank">
                <Image
                  src={Telegram}
                  alt="Telegram-logo"
                  aria-label="Telegram"
                  className="footernew__twitterIcon"
                  width={20}
                  height={17}
                />
              </Link>
              <Link
                href="https://www.instagram.com/seed.photo?igsh=MWVoZTIwaGY1bWc4eg=="
                target="_blank"
                aria-label="instagram"
              >
                <Image
                  width={24}
                  height={25}
                  loading="lazy"
                  src={Instagram}
                  alt="Instagram-logo"
                />
              </Link>
              <Link
                href="https://www.linkedin.com/company/seed-photo/"
                target="_blank"
                aria-label="linkedin"
              >
                <Image
                  width={24}
                  height={24}
                  loading="lazy"
                  src={LinkedIn}
                  alt="LinkedIn-logo"
                />
              </Link>
              <Link
                href="https://x.com/seedphoto?s=21&t=KXLoV2_QOHMF1c2d5LI5wA"
                target="_blank"
              >
                <Image
                  className="with-back"
                  src={Twitter}
                  alt="twitter-logo"
                  aria-label="Twitter"
                  width={17}
                  height={17}
                />
              </Link>
              <Link
                href="https://www.tiktok.com/@seed.photo/"
                target="_blank"
                aria-label="tik tok"
              >
                <Image
                  width={21}
                  height={25}
                  loading="lazy"
                  src={Tiktok}
                  alt="Tiktok-logo"
                />
              </Link>
              {/* <Link
                href="https://discord.gg/d63Gnfq9wz"
                target="_blank"
                aria-label="discord"
              >
                <Image
                  width={22}
                  height={17}
                  loading="lazy"
                  src={Discord}
                  alt="Discord-logo"
                />
              </Link> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default React.memo(Footer);
