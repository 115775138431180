import google from "@/assets/images/icons/icon-google-app.svg";
import { selectJoinNewslatterData } from "@/config/helpers/createReduxSelectors";
import { NewsLetterData, ResetNewsLetterData } from "@/store/actions";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EnumToastType } from "../constants/layout";
import { raiseToastMessage } from "./utiles";

function JoinNewslatter() {
  const dispatch = useDispatch();
  const { success, news } = useSelector(selectJoinNewslatterData);

  const [isJoinNewsletterOpen, setIsJoinNewsletterOpen] = useState(false);
  const [Email, setEmail] = useState(false);

  const handleClick = () => {
    setIsJoinNewsletterOpen(!isJoinNewsletterOpen);
    console.log(isJoinNewsletterOpen);
  };

  const handleSubmit = () => {
    const EmailData = new FormData();
    EmailData.append("email", Email);
    dispatch(NewsLetterData(EmailData));
  };

  useEffect(() => {
    if (success == true) {
      {
        raiseToastMessage(
          "You are Joining Successfully",
          3000,
          true,
          EnumToastType.Success
        );
      }
      setTimeout(() => {
        dispatch(ResetNewsLetterData());
      }, 6000);
    }
  }, [dispatch, success]);

  return (
    <>
      <div className="footernew__col">
        <div
          className={`footernew__head ${isJoinNewsletterOpen ? "" : "active"}`}
          onClick={handleClick}
        >
          Join Newsletter
          <svg className="iconnew icon-arrow-bottom">
            <use xlinkHref="#icon-arrow-bottom"></use>
          </svg>
        </div>
        <div
          className="footernew__body"
          style={
            isJoinNewsletterOpen === true
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div id="newsletter">
            <div className="subscription">
              <input
                className="subscription__input"
                autoComplete="email"
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                required="required"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div>
                <div
                  className="footernew_button-submit "
                  onClick={handleSubmit}
                >
                  <i className=" icon-arrow_forward icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link
          href="https://play.google.com/store/apps/details?id=app.seed.photo&amp;hl=en"
          className="footernew__download_button"
          target="_blank"
        >
          <Image
            width={19}
            height={19}
            loading="lazy"
            src={google}
            alt="googleplay"
          />
          <span>Google play</span>
        </Link>
      </div>
    </>
  );
}

export default React.memo(JoinNewslatter);
